import React from 'react';
import './TaxonomyBadge.css';

interface TaxonomyBadgeProps {
  level: 'category' | 'genre' | 'subgenre' | 'tag';
  name: string;
}

export function TaxonomyBadge({ level, name }: TaxonomyBadgeProps) {
  return (
    <span className={`taxonomy-badge ${level}`}>
      {name}
    </span>
  );
}