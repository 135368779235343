import './App.css';
import EventMapClusterComponent from './EventMapClusterComponent';
import HeaderLogo from './HeaderLogo';
import QuickFilters from './QuickFilters';
import ResultsTiles from './ResultsTiles';
import SearchBar from './SearchBar';


function App() {
  return (
    <>
      <title>FanFlame | Stoke Your Fire</title>
      <div className="App">
        <header className="App-header">
          <div className="App-header-top">
            <div className="header-left">
              <HeaderLogo />
            </div>
            <div className="header-center">
              {/*<SearchBar />*/}
            </div>
          </div>
          <QuickFilters />
        </header>
        <div className="App-body">
          <div className="results-tiles-container">
            <ResultsTiles />
          </div>
          <div className="map-container">
            <EventMapClusterComponent />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;