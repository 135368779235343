import {create} from 'zustand';

interface FilterStore {
  classifications: number[]; // Support multiple filters
  setClassifications: (classification: number) => void; // Method to set the filter
  clearClassifications: () => void; // Method to clear all filters

  tags: number[]; // Array of active tags
  setTags: (tag: number) => void; // Method to toggle a tag
  clearTags: () => void; // Method to clear all tags
}

export const useFilterStore = create<FilterStore>((set) => ({
  classifications: [],
  setClassifications: (classification) =>
    set((state) => ({
      classifications: state.classifications.includes(classification) ? [] : [classification], // Ensure only one filter is active
      tags: [], // Clear tags when a classification is selected
    })),
    clearClassifications: () => set({ classifications: [] }), // Clear all filters

    tags: [],
    setTags: (tag) =>
      set((state) => ({
        tags: state.tags.includes(tag) ? [] : [tag], // Ensure only one tag is active
        classifications: [], // Clear classifications when a tag is selected
      })),
    clearTags: () => set({ tags: [] }),
}));
