import React, { useEffect, useRef, useState } from 'react';
import './ResultsTiles.css';
import { useEvents, Classification } from './hooks/useResults';
import { TaxonomyBadge } from './TaxonomyBadge';
import { useFilterStore } from './store/FilterStore';


const ResultsTiles: React.FC = () => {
  //  const { data, error, isLoading } = useEvents();
  //const classifications = useFilterStore((state) => state.classifications);
  //const tags = useFilterStore((state) => state.tags);
  const { data, error, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useEvents();
  const observerRef = useRef<HTMLDivElement | null>(null);


  //const events_page = data?.events_page;
  const allEvents = data?.pages.flatMap((page) => page.events_page.events) || [];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null, // Use the viewport
        rootMargin: '200px', // Trigger slightly before reaching the bottom
        threshold: 0.1, // Trigger when 10% of the sentinel is visible
      }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [fetchNextPage, hasNextPage]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const optionsDate: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      month: 'short',
      day: '2-digit'
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    };
    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
    return `${formattedDate} • ${formattedTime}`;
  };

  const getFilteredClassifications = (classifications: Classification[]) => {
    // Map classifications to their IDs for child-parent lookup
    const childIds = new Set(classifications.map((c) => c.parent_id));
    return classifications
      .filter((c) => !childIds.has(c.id)) // Keep only items that are not parents
      .sort((a, b) => {
        // Sort by level: category > genre > subgenre
        const levelOrder = { category: 0, genre: 1, subgenre: 2 };
        return levelOrder[a.level] - levelOrder[b.level];
      });
  };

  if (isLoading) return <div className="results-tiles">Loading...</div>;
  if (error) return <div className="results-tiles">Error: {error.message}</div>;

  return (
    <div className="results-tiles">
      {allEvents.map((event) => (
        <div key={event.id} className="event-tile">
          <div className="image-container">
            {event.image_url && (
              <img src={event.image_url} alt={event.name} className="event-image" />
            )}
            <div className="date-overlay">
              <span>{formatDate(event.start)}</span>
            </div>
          </div>
          <h2 className="event-title">{event.name}</h2>
          <p className="event-details">
            <span className="event-venue">{event.venue.name}</span>
          </p>
          <div className="event-classifications">
            {getFilteredClassifications(event.classifications).map((classification) => (
              <TaxonomyBadge
                key={classification.id}
                level={classification.level}
                name={classification.name}
              />
            ))}
            {event.tags.map((tag) => (
              <TaxonomyBadge key={tag.id} level="tag" name={tag.name} />
            ))}
          </div>
          <a
            href={event.ticket_url}
            target="_blank"
            rel="noopener noreferrer"
            className="event-button"
          >
            Get Tickets
          </a>
        </div>
      ))}
      {/* Sentinel for triggering the next page */}
      <div ref={observerRef} className="loading-sentinel" />
      {/* Small loading widget */}
      {isFetchingNextPage && <div className="loading-spinner">Loading more...</div>}
    </div>
  );
};

export default ResultsTiles;
