// src/hooks/useEvents.ts
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { FeatureCollection, Point } from 'geojson';
import { useFilterStore } from '../store/FilterStore';

export interface EventVenue {
  id: number;
  name: string;
  location: {
    lat: number;
    lon: number;
  };
}

export interface Tag {
  id: number;
  name: string;
}

export interface Classification {
  id: number;
  name: string;
  level: 'category' | 'genre' | 'subgenre';
  parent_id: number;
  parent: number;
}

export interface Event {
  id: number;
  name: string;
  location: {
    lat: number;
    lon: number;
  };
  start: string;
  ticket_url: string;
  image_url: string;
  venue: EventVenue;
  classifications: Classification[];
  tags: Tag[];
}

export interface EventsPage {
  events: Event[];
  event_count: number;
  page: number;
  page_size: number;
  num_pages: number;
}

export interface EventsResponse {
  events_page: EventsPage;
  venues: FeatureCollection<Point>;
}

const fetchEvents = async ({
  pageParam = 1, // Default to the first page if no pageParam is provided
  classificationIds = [],
  tagIds = [],
}: {
  pageParam?: number;
  classificationIds?: number[];
  tagIds?: number[];
}): Promise<EventsResponse> => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const queryParams = new URLSearchParams({
    upcoming_within: 'P30D',
    page: String(pageParam),
    page_size: '30',
  });
  classificationIds.forEach((id) => {
    queryParams.append('classifications', String(id));
  });
  tagIds.forEach((id) => {
    queryParams.append('tags', String(id));
  });
  const response = await fetch(`${apiUrl}/api/events/results?${queryParams.toString()}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const useEvents = () => {
  const classificationIds = useFilterStore((state) => state.classifications);
  const tagIds = useFilterStore((state) => state.tags);
  return useInfiniteQuery<EventsResponse, Error>({
    queryKey: ['events', classificationIds, tagIds],
    queryFn: ({ pageParam }) =>
      fetchEvents({ pageParam: pageParam as number | undefined, classificationIds, tagIds }),
    initialPageParam: 1, // Set the initial page parameter
    getNextPageParam: (lastPage) => {
      const { page, num_pages } = lastPage.events_page;
      return page < num_pages ? page + 1 : undefined; // Return the next page or undefined if it's the last page
    },
  });
};
