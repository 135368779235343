import type { LayerProps } from 'react-map-gl';

export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'event_source',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'event_count_sum'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
    'circle-radius': ['step', ['get', 'event_count_sum'], 20, 100, 30, 750, 40]
  }
};

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'event_source',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{event_count_sum}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12
  }
};

export const unclusteredPointLayer: LayerProps = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'event_source',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#11b4da',
    'circle-radius': 8,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff'
  }
};

export const venueCirlceLayer: LayerProps = {
  id: 'venue-circle',
  type: 'circle',
  source: 'event_source',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#4caf50',
    'circle-radius': 16,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff'
  }
};

export const venueCountLayer: LayerProps = {
  id: 'venue-count',
  type: 'symbol',
  source: 'event_source',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'text-field': ['get', 'event_count'],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12
  }
};
export const venueNameLayer: LayerProps = {
  id: 'venue-name',
  type: 'symbol',
  source: 'event_source',
  filter: ['!', ['has', 'point_count']], // Unclustered points only
  layout: {
    'text-field': ['get', 'name'], // Show the venue name
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': ['interpolate', ['linear'], ['zoom'], 10, 10, 14, 14, 20, 18],
    'text-anchor': 'top', // Position the text relative to the top of the symbol
    'text-offset': [0, 1.5], // Offset to place the name below the circle
  },
};