import React from 'react';
import './HeaderLogo.css';

const HeaderLogo: React.FC = () => {
  return (
    <div className="header-with-logo">
      <img src="/fanflame_small.png" className="logo" alt="FanFlame Logo" />
      <span className="title">FanFlame</span>
    </div>
  );
};

export default HeaderLogo;