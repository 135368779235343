import { FeatureCollection, Point } from 'geojson';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import { Layer, Map, MapRef, Source } from 'react-map-gl';
import { clusterCountLayer, clusterLayer, unclusteredPointLayer, venueCirlceLayer, venueCountLayer, venueNameLayer } from './layers';
import { useEvents } from './hooks/useResults';



const EventMapClusterComponent: React.FC = () => {
  const { data, error, isLoading } = useEvents();
  const mapRef = useRef<MapRef | null>(null);

  // Always use venues from the first page
  const venues = data?.pages[0]?.venues;
  //const onLoaded = (map: mapboxgl.Map) => {
  //  map.resize();
  //}

  // Handle cluster click event
  const onClick = (event: mapboxgl.MapLayerMouseEvent) => {
    const feature = event.features?.[0];
    if (!feature || !feature.properties?.cluster_id) return;

    const clusterId = feature.properties.cluster_id;
    const mapboxSource = mapRef.current?.getSource('event_source') as mapboxgl.GeoJSONSource;

    if (mapboxSource) {
      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          console.error('Error getting cluster expansion zoom:', err);
          return;
        }

        const coordinates = (feature.geometry as Point).coordinates as [number, number];

        mapRef.current?.easeTo({
          center: coordinates,
          zoom,
          duration: 500,
        });
      });
    }
  };

    // Handle mouseenter for clusters
    const onMouseEnter = () => {
      if (mapRef.current) {
        mapRef.current.getCanvas().style.cursor = 'pointer';
      }
    };
    
    const onMouseLeave = () => {
      if (mapRef.current) {
        mapRef.current.getCanvas().style.cursor = '';
      }
    };
    


  return (
    <>
      <Map
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        initialViewState={{
          longitude: -122.441321,
          latitude: 37.768732,
          zoom: 12
        }}
        //style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        interactiveLayerIds={[clusterLayer.id as string]}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {venues && (
          <Source
            id="event_source"
            type="geojson"
            data={venues}
            cluster={true}
            clusterMaxZoom={14}
            clusterRadius={50}
            clusterProperties={{ event_count_sum: ["+", ["get", "event_count"]] }}>
            <Layer {...clusterLayer} />
            <Layer {...clusterCountLayer} />
            <Layer {...venueCirlceLayer} />
            <Layer {...venueCountLayer} />
            <Layer {...venueNameLayer} />
            {/*<Layer {...unclusteredPointLayer} />*/}
          </Source>
        )}
      </Map>
      {/* Optionally, show a loading spinner or message while data is loading */}
      {isLoading && <div className="loading-spinner">Loading venues...</div>}

      {/* Optionally handle errors */}
      {error && <div className="error-message">Error loading venues: {error.message}</div>}
    </>
  );
}

export default EventMapClusterComponent;
