import React, { useEffect, useRef, useState } from 'react';
import './QuickFilters.css';

import { ReactComponent as SportsIcon } from './icons/stadium.svg';
import { ReactComponent as MusicIcon } from './icons/music_note.svg';
import { ReactComponent as ArtsIcon } from './icons/performing_arts.svg';
import { ReactComponent as FilterIcon } from './icons/magnifying_glass.svg';
import { ReactComponent as ComedyIcon } from './icons/studio_microphone.svg';
import { ReactComponent as DanceIcon } from './icons/dance.svg';
import { ReactComponent as CommunityIcon } from './icons/houses.svg';
import { ReactComponent as RockIcon } from './icons/guitar.svg';
import { ReactComponent as PopIcon } from './icons/sparkling_heart.svg';
import { ReactComponent as BasketballIcon } from './icons/basketball.svg';
import { ReactComponent as SoccerIcon } from './icons/soccer.svg';
import { ReactComponent as FolkIcon } from './icons/violin.svg';
import { ReactComponent as RhythmBluesIcon } from './icons/keyboard.svg';
import { ReactComponent as LgbtqIcon } from './icons/rainbow_flag.svg';
import { ReactComponent as FamilyIcon } from './icons/teddy_bear.svg';
import { ReactComponent as HockeyIcon } from './icons/hockey.svg';
import { useEvents } from './hooks/useResults';
import { useFilterStore } from './store/FilterStore';

// Example data structure
const categories = [
  { id: 331, type: "classification", label: 'Sports', Icon: SportsIcon },
  { id: 252, type: "classification", label: 'Music', Icon: MusicIcon },
  { id: 406, type: "classification", label: 'Arts', Icon: ArtsIcon },
  { id: 428, type: "classification", label: 'Comedy', Icon: ComedyIcon },
  { id: 412, type: "classification", label: 'Dance', Icon: DanceIcon },
  { id: 10, type: "tag", label: 'LGBTQ+', Icon: LgbtqIcon },
  { id: 1, type: "tag", label: 'Family', Icon: FamilyIcon },
  { id: 253, type: "classification", label: 'Rock', Icon: RockIcon },
  { id: 259, type: "classification", label: 'Pop', Icon: PopIcon },
  { id: 334, type: "classification", label: 'Basketball', Icon: BasketballIcon },
  { id: 337, type: "classification", label: 'Hockey', Icon: HockeyIcon },
  { id: 307, type: "classification", label: 'Folk', Icon: FolkIcon },
  { id: 271, type: "classification", label: 'R&B', Icon: RhythmBluesIcon },
  { id: 469, type: "classification", label: 'Community', Icon: CommunityIcon },
  { id: NaN, type: "classification", label: 'More Filters', Icon: FilterIcon },
];


const QuickFilters: React.FC = () => {
  const classifications = useFilterStore((state) => state.classifications);
  const setClassifications = useFilterStore((state) => state.setClassifications);

  const tags = useFilterStore((state) => state.tags);
  const setTags = useFilterStore((state) => state.setTags);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const updateScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', updateScrollButtons);
      updateScrollButtons(); // Initial check
    }
    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', updateScrollButtons);
      }
    };
  }, []);


  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -400, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    console.log('Scrolling right');
    if (scrollContainerRef.current) {
      console.log('Scrolling right ref present', scrollContainerRef.current);
      scrollContainerRef.current.scrollBy({ left: 400, behavior: 'smooth' });
    }
  };


  const handleFilterClick = (id: number, type: string) => {
    if (isNaN(id)) {
      console.log('More Filters button clicked - no action taken.');
      return; // Do nothing for "More Filters"
    }
    console.log('Filter clicked:', id, type);
    if (type === 'classification') {
      setClassifications(id);
    } else if (type === 'tag') {
      setTags(id);
    }
    // Call your backend or store to apply the filter
    // e.g. filterEventsByCategory(categoryId);
  };
  return (
    <div className="quick-filters-container">
      {canScrollLeft && (
        <button className="scroll-button left" onClick={scrollLeft}>
          ←
        </button>
      )}
      <div className="quick-filters" ref={scrollContainerRef}>
        {categories.map(({ id, type, label, Icon }) => {
          const isActive =
            type === 'classification' ? classifications.includes(id) : tags.includes(id);

          return (
            <button
              key={`${type}-${id}`} // Ensure unique keys for different types
              className={`quick-filter-button ${isActive ? 'active' : ''}`}
              onClick={() => handleFilterClick(id, type)}
            >
              <div className="icon-wrapper">
                <Icon className="filter-icon" />
              </div>
              <div className="label">{label}</div>
            </button>
          );
        })}
      </div>
      {canScrollRight && (
        <button className="scroll-button right" onClick={scrollRight}>
          →
        </button>
      )}
    </div>
  );
};

export default QuickFilters;
